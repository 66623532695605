<template>
  <section>
    <request-materials
      class="mt-4"
      title="Bodega Móvil | Solicitudes de Material"
      :typeRequest="TYPE_REQUESTS_MATERIALS.mobile_warehouses"
    ></request-materials>
  </section>
</template>

<script>
import RequestMaterials from "@/router/views/requestMaterials/index.vue";
import { TYPE_REQUESTS_MATERIALS } from "@/constants/requestMaterial";

export default {
  components: {
    RequestMaterials,
  },
  data() {
    return {
      TYPE_REQUESTS_MATERIALS,
    };
  },
};
</script>

<style></style>
